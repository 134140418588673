import * as React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

const Logo = styled(Link)`
  display: flex;
  align-items: center;
`

const Head = styled.header`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  background: #f23c24;
  width: 1024px;
  height: 68px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1024px 0 0 0 #f23c24, -1024px 0 0 0 #f23c24;
  
  margin: 0 auto;

  box-sizing: border-box;
  padding: 0 56px;
`

const LogoImage = styled.div`
  width: 109px;
  height: 36px;
  background-image: url(${require('../images/logo.png').default});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const LogoText = styled.div`
  width: 89px;
  height: 24px;
  background-image: url(${require('../images/logo-text.svg').default});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
`

const NavTab = styled(Link)`
  width: 97px;
  height: 34px;
  border: 2px solid rgba(255,255,255,0.43);
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;


  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  
  margin-left: 28px;
  
  &:hover {
    border: 2px solid rgba(255,255,255,0.43);
  }
  
  &:active,
  &.active {
    background: #f23c24;
    border: 2px solid rgba(255,255,255,0.43);
  }
`

const Header = () => (
  <Head>
    <Logo to='/'>
      <LogoImage />
    </Logo>
    <Nav>
      {/*<NavTab to="/join" activeStyle={{background: '#ffe46e', border: '2px solid rgba(255,255,255,0.43)'}}>商家入驻</NavTab>*/}
      <NavTab to="/about" activeStyle={{background: '#f23c24', border: '2px solid rgba(255,255,255,0.43)'}}>关于我们</NavTab>
    </Nav>
  </Head>
)

export default Header
